<template>
  <section class="section section--black">
    <div class="section__wrapper">
      <h2 class="text-44 text-bold title-mb">Этапы премии</h2>
      <div class="stages">
        <ul class="stages__list">
          <BlackStage
            v-for="(stage, index) in content?.stages"
            :key="index"
            :stage="stage"
          />
        </ul>
        <div class="stages-bar">
          <div
            class="stages-bar__complite"
            :style="{ width: progressBarWidth() }"
          ></div>
          <div
            v-for="({ pastOrCurrent }, index) in content?.stages"
            :key="index"
            :class="stagesClasses(pastOrCurrent, index)"
          />
        </div>
      </div>
      <div id="experts" ref="experts" class="experts">
        <section class="expert-gsap">
          <h2 class="text-44 text-bold title-mb">Экспертный совет</h2>
          <ul
            v-if="!!senates?.length == true"
            ref="cards"
            class="experts__list"
          >
            <li v-for="senate in senates" :key="senate.id" class="expert">
              <img
                v-if="senate?.attributes?.photo?.data !== null"
                :src="
                  useStrapiMediaUrl(
                    senate?.attributes?.photo?.data?.attributes?.url,
                  )
                "
                class="expert__photo"
                loading="lazy"
              />
              <div v-else class="expert__photo-no"></div>
              <div class="expert__content">
                <div class="text-16 text-bold">
                  {{ senate.attributes.username }}
                </div>
                <span class="text-12 expert__regalia">
                  {{ senate.attributes.regalia }}
                </span>
                <span class="text-12 text-bold">
                  {{ senate.attributes.company }}
                </span>
              </div>
            </li>
          </ul>
          <p class="experts__welcome">
            Приглашаем вас стать частью экспертного сообщества. Заполните форму
            и ваше фото появится выше
          </p>
          <UiSliderButton text="хочу в совет" @click="toggleModal(true)" />
        </section>
      </div>
    </div>
  </section>
  <Teleport to="#teleports">
    <UiModal :on-close="toggleModal" :is-visible="isModalVisible">
      <BlackApplication :is-visible="isModalVisible" />
    </UiModal>
  </Teleport>
</template>
<script setup lang="ts">
import type { Global } from "~/types/strapiContent"
// const { $gsap, $ScrollTrigger } = useNuxtApp()
// $gsap.registerPlugin($ScrollTrigger)

const globalData = await getGlobals()
const senates = await getSenates()
const content = computed(() => {
  return globalData?.attributes
})
const stagesClasses = (active, index) => {
  const classes = ["stages-bar__round", `stages-bar__round--${index + 1}`]
  if (active) classes.push("stages-bar__round--active")

  return classes
}
const passedStages = computed(() => {
  const stages = content?.value?.stages
  const count = stages?.length
  const passed = stages?.filter((x) => x.pastOrCurrent === true)?.length
  return {
    count,
    passed,
  }
})
const progressBarWidth = () => {
  if (!passedStages.value) return

  return (
    String((passedStages?.value?.passed * 100) / passedStages.value?.count) +
    "%"
  )
}

const cards = ref()
const isModalVisible = ref(false)

const toggleModal = (value) => {
  isModalVisible.value = value
}
// const slidersPerPage = ref()
// const countPaddingByWindowWidth = ref()
// const windowWidth = ref()
// function handleSizeWindow() {
//   windowWidth.value = window.innerWidth
//   if (windowWidth.value >= 1081) {
//     slidersPerPage.value = 4
//     countPaddingByWindowWidth.value = 140 - 20
//   }
//   if (windowWidth.value <= 1080) {
//     slidersPerPage.value = 2
//     countPaddingByWindowWidth.value = 72 - 20
//   }
//   if (windowWidth.value <= 580) {
//     slidersPerPage.value = 1
//     countPaddingByWindowWidth.value = 32 - 20
//   }
// }
// onBeforeMount(() => {
//   windowWidth.value = window.innerWidth
//   window.addEventListener("resize", handleSizeWindow)
//   handleSizeWindow()
// })
// const expertsRef = ref()
// let ctx
// onMounted(() => {
//   const blockWidth = 1300
//   const items = $gsap.utils.toArray(".expert")
//   let totalShift =
//     310 * items.length +
//     (20 * items.length + countPaddingByWindowWidth.value) -
//     windowWidth.value
//   if (windowWidth.value > 1440)
//     totalShift += (windowWidth.value - blockWidth) / 2

//   ctx = $gsap.context(() => {
//     const trig = document.querySelector(".expert-gsap")
//     $gsap.to(items, {
//       x: `-${totalShift}`,
//       scrollTrigger: {
//         trigger: trig,
//         scrub: 1,
//         pin: true,
//         end: `+=${totalShift}`,
//       },
//     })
//   }, expertsRef.value)
// })
// onUnmounted(() => {
//   ctx.revert() // <- Easy Cleanup!
// })
</script>
<style lang="scss" scoped>
.section {
  &--black {
    padding: 100px 70px;
    position: relative;
    background-color: $primary-black;
    border-radius: 20px 20px 20px 20px;
    overflow: hidden;
    color: #ffffff;
    z-index: 1;
    @include md {
      padding: 100px 36px;
    }
    @include sm {
      margin: -20px 0 0 0;
      padding: 80px 16px;
    }
  }
}
.experts {
  &__list {
    margin: 0 0 40px 0;
    min-height: 427px;
    display: flex;
    gap: 20px;
  }
  &__welcome {
    margin: 0 0 40px 0;
    font-size: 22px;
    line-height: 26px;
    max-width: 640px;
    width: 100%;

    @include md {
      width: 270px;
      font-size: 18px;
      line-height: 21px;
    }
  }
  &__want {
    width: 100%;
    height: 107px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) -30%,
      rgba(106, 69, 255, 1) 70%
    );

    border-radius: 100px;
    transition: ease-in-out 0.335s;
    &:hover {
      opacity: 0.5;
    }
    @include md {
      font-size: 16px;
      line-height: 22px;
    }
    @include sm {
      height: 77px;
      font-size: 14px;
      line-height: 19px;
    }
  }
}
.expert {
  max-width: 310px;
  height: 426px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color-darker-gray;
  border-radius: 10px;
  &__photo {
    display: block;
    width: 310px;
    height: 260px;
    object-fit: cover;
    border-radius: 10px;
    &-no {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 310px;
      min-height: 260px;
      background-color: $color-light-gray;
      border-radius: 10px;
    }
  }
  &__regalia {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__content {
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      margin: 10px 0 auto 0;
      &:last-child {
        margin: 0 0 0 0;
      }
    }
  }
}
.stages__list {
  margin: 0 0 70px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @include md {
    margin: 0 0 0 0;
    gap: 80px 120px;
    grid-template-columns: repeat(2, 1fr);
  }
  @include sm {
    margin: 0 0 0 0;
    grid-template-columns: 1fr;
    gap: 60px;
  }
}

.stages-bar {
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  position: relative;
  @include md {
    visibility: hidden;
    display: none;
  }
  &__complite {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgb(106, 69, 255) 50%,
      rgb(255, 255, 255)
    );
    width: 25%;
    z-index: 2;
  }
  &__round {
    width: 16px;
    height: 16px;
    display: block;
    background-color: #ffffff;
    position: absolute;
    top: -6px;
    border-radius: 50%;
    z-index: 3;
    &--active {
      background-color: rgb(106, 69, 255);
    }
    &--1 {
      left: -2px;
    }
    &--2 {
      left: 25%;
    }
    &--3 {
      left: calc(50% + 8px);
    }
    &--4 {
      left: calc(75% + 8px);
    }
  }
}
.expert-gsap {
  padding: 110px 0 0 0;
  margin: 0 0 40px 0;
  @include md {
    padding: 100px 0 0 0;
  }
  @include sm {
    padding: 80px 0 0 0;
  }
}
</style>
