<template>
  <div class="authorization">
    <h2>Хочу в совет</h2>
    <div v-if="!submit" class="authorization__form">
      <UiInput
        v-model="formData.name"
        :class="{ 'input-error': errors.name }"
        type="text"
        placeholder="ФИО"
        @input="validateName"
      />
      <div v-if="errors.name" class="error-message">{{ errors.name }}</div>
      <UiInput
        v-model="formData.position"
        :class="{ 'input-error': errors.position }"
        type="text"
        placeholder="Должность"
        @input="validateRegalia"
      />
      <div v-if="errors.position" class="error-message">
        {{ errors.position }}
      </div>
      <UiMaskInput
        v-model="formData.phone"
        :class="{ 'input-error': errors.phone }"
        type="text"
        placeholder="Телефон"
        @input="validatePhoneInput"
      />
      <div v-if="errors.phone" class="error-message">{{ errors.phone }}</div>
      <UiInput
        v-model="formData.email"
        :class="{ 'input-error': errors.email }"
        type="text"
        placeholder="Почта"
        @input="validateEmailInput"
      />
      <div v-if="errors.email" class="error-message">{{ errors.email }}</div>
      <UiInput
        v-model="formData.company"
        :class="{ 'input-error': errors.company }"
        type="text"
        placeholder="Название компании"
        @input="validateCompanyName"
      />
      <div v-if="errors.company" class="error-message">
        {{ errors.company }}
      </div>
      <div class="flex-column gap-14 mt-15px">
        <div class="authorization__checkbox">
          <UiCheckbox v-model="formData.agreeWithPolicy" />
          <label>
            Ознакомлен с
            <NuxtLink :to="{ name: 'oferta' }"> положением о премии </NuxtLink>
          </label>
        </div>
        <div v-if="errors.terms" class="error-message">{{ errors.terms }}</div>
      </div>
    </div>
    <div v-else class="flex-column-center gap-40 mt-40px">
      <span class="text-16 text-purple text-center">
        Заявка отправлена! Подтвердите адрес, чтобы завершить обработку вашей
        заявки.
      </span>
      <p class="text-16 text-center">
        Ответное письмо будет отправлено на почту <br />
        {{ formData.email }}
        <span class="text-12">почту подтвердить нужно в течении 24 часов</span>
      </p>
    </div>
    <div v-if="errorFromApi" class="error-message">{{ errorFromApi }}</div>
    <UiButton v-if="!submit" class="mt-15px" @click="handleSubmit">
      Отправить
    </UiButton>
  </div>
</template>

<script setup>
const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
})
defineEmits(["changeView", "close"])
const submit = ref(false)
const errorFromApi = ref("")

const formData = ref({
  name: "",
  position: "",
  phone: "",
  email: "",
  company: "",
  agreeWithPolicy: false,
})

const errors = ref({
  name: "",
  position: "",
  phone: "",
  email: "",
  company: "",
  terms: "",
})

const validateName = () => {
  if (!formData.value.name) {
    errors.value.name = "Введите ФИО."
  } else {
    errors.value.name = ""
  }
}

const validateRegalia = () => {
  if (!formData.value.position) {
    errors.value.position = "Введите должность."
  } else {
    errors.value.position = ""
  }
}

const validatePhoneInput = () => {
  if (!validatePhone(formData.value.phone)) {
    errors.value.phone = "Введите корректный номер телефона."
  } else {
    errors.value.phone = ""
  }
}

const validateEmailInput = () => {
  if (!validateEmail(formData.value.email)) {
    errors.value.email = "Введите корректный адрес электронной почты."
  } else {
    errors.value.email = ""
  }
}

const validateCompanyName = () => {
  if (!formData.value.company) {
    errors.value.company = "Введите название компании."
  } else {
    errors.value.company = ""
  }
}

const handleSubmit = async () => {
  validateName()
  validateRegalia()
  validatePhoneInput()
  validateEmailInput()
  validateCompanyName()

  if (!formData.value.agreeWithPolicy) {
    errors.value.terms = "Ознакомьтесь с положением о премии"
  } else {
    errors.value.terms = ""
  }
  if (
    !errors.value.name &&
    !errors.value.position &&
    !errors.value.phone &&
    !errors.value.email &&
    !errors.value.company &&
    !errors.value.terms
  ) {
    const data = {
      username: formData.value.name,
      regalia: formData.value.position,
      phone: formData.value.phone,
      email: formData.value.email,
      company: formData.value.company,
    }

    try {
      const response = await sendSenate(data)
      if (response?.data) {
        submit.value = true
      }
    } catch (error) {
      errorFromApi.value = formErrorHandler(error.error?.message)
    }
  }
}

watch(
  () => props.isVisible,
  (newVal) => {
    if (newVal) {
      submit.value = false
      errors.value = {
        name: "",
        regalia: "",
        phone: "",
        email: "",
        companyName: "",
        file: "",
        terms: "",
      }
    }
    formData.value = {
      name: "",
      regalia: "",
      phone: "",
      email: "",
      companyName: "",
      file: null,
      agreeWithPolicy: false,
      agreeWithPersonalData: false,
    }
  },
)
</script>

<style lang="scss" scoped>
.authorization {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: $color-dark-gray;
  max-height: 95dvh;
  overflow: auto;
  margin: auto 0;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    display: none;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
    margin: 40px 0 30px;
    align-items: flex-start;

    .input-container {
      width: 100%;
    }
  }
  &__checkbox {
    display: flex;
    gap: 16px;
    a {
      color: $color-purple;
      cursor: pointer;
    }
  }
}

.input-error {
  border: 1px solid red;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: -10px;
}
</style>
